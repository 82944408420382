import { useState, useEffect } from 'react';
import { Modal, Heading, Button } from '@lululemon/ecom-pattern-library';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from './device-not-compatible-modal.module.scss';

function isMobileOrTablet() {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /android|iPad|iPhone|iPod/i.test(userAgent);
}

const DeviceNotCompatibleModal = () => {
  const [visible, setVisible] = useState(false);
  const { formatMessage } = useFormat({ name: 'common' });

  const title = formatMessage({
    id: 'mobile.error.title',
    defaultMessage: 'Device not compatible',
  });
  const description = formatMessage({
    id: 'mobile.error.description',
    defaultMessage: 'Please open the strategic sales website on a desktop computer.',
  });
  const buttonText = formatMessage({
    id: 'mobile.error.buton',
    defaultMessage: 'Got it',
  });

  function checkDeviceCompatibility() {
    const hasShownAlert = localStorage.getItem('hasShownAlert');
    if (!hasShownAlert && isMobileOrTablet()) {
      setVisible(true);
      localStorage.setItem('hasShownAlert', 'true');
    }
  }

  useEffect(() => {
    checkDeviceCompatibility();
  }, []);

  return (
    <Modal
      classes={{ modal: styles.modal, modalContent: styles.modalContent }}
      aria-label={title}
      onRequestClose={() => null}
      visible={visible}
      data-testid="device-not-compatible__container_test-id"
      cancelProps={{ className: styles.hideCloseButton }}
    >
      <Heading className={styles.modalHeader}>{title}</Heading>
      <p className={styles.content}>{description}</p>
      <Button kind="secondary" className={styles.button} onClick={() => setVisible(false)} type="submit">
        {buttonText}
      </Button>
    </Modal>
  );
};

export default DeviceNotCompatibleModal;
