import React from 'react';
import cx from 'classnames';
import { Category } from 'shared/types/product/Category';
import { Cell as LayoutElement } from './cell';
import { highlightClassNames, TasticWrapper } from './component';
import { Errors } from './errors';
import { Grid } from './grid';
import { Cell as LayoutElementType, Tastic, TasticRegistry, CellConfiguration, PagePreviewDataResponse } from './types';
import DeviceNotCompatibleModal from '../../components/device-not-compatible-modal';
import checkForCategories from '../../helpers/utils/checkForCategories';

function deviceVisibility(conf: CellConfiguration) {
  return `${conf.mobile ? 'block' : 'hidden'} ${conf.tablet ? 'md:block' : 'md:hidden'} ${
    conf.desktop ? 'lg:block' : 'lg:hidden'
  }`;
}

export function FrontasticRenderer({
  data,
  tastics = {},
  gridClassName,
  currentHighlight,
}: {
  data: PagePreviewDataResponse & {
    categories: { items: Category[] };
    serverState?: any;
    serverUrl: string;
  };
  tastics: TasticRegistry;
  gridClassName?: string;
  wrapperClassName?: string;
  currentHighlight?: string;
}) {
  const categoriesData = data.categories.items ?? checkForCategories();

  return (
    <div className="flex min-h-screen flex-col items-center justify-start bg-[#fafafa]">
      {process && process.env.NODE_ENV !== 'production' && <Errors />}
      <Grid wrapperClassName="w-full">
        <div id="top-of-page" tabIndex={-1} />

        {data?.page?.sections?.kit?.layoutElements.map((layoutElement: LayoutElementType) => (
          <LayoutElement
            size={layoutElement.configuration.size}
            className={highlightClassNames(currentHighlight === layoutElement.layoutElementId)}
            key={layoutElement.layoutElementId}
          >
            {layoutElement.tastics.map((t) => (
              <TasticWrapper
                tastics={tastics}
                key={t.tasticId}
                data={t}
                categories={categoriesData}
                dataSources={data.data.dataSources}
                pageFolder={data.pageFolder}
                highlight={currentHighlight === t.tasticId}
                previewId={data?.previewId}
                serverUrl={data.serverUrl}
                serverState={data.serverState}
              />
            ))}
          </LayoutElement>
        ))}
      </Grid>
      <Grid
        gridClassName={gridClassName}
        wrapperClassName={`w-full ${highlightClassNames(currentHighlight === 'head')}`}
      >
        {data?.page?.sections?.head?.layoutElements.map((layoutElement: LayoutElementType) => (
          <LayoutElement
            size={layoutElement.configuration.size}
            className={cx(
              highlightClassNames(currentHighlight === layoutElement.layoutElementId),
              deviceVisibility(layoutElement.configuration),
            )}
            key={layoutElement.layoutElementId}
          >
            {layoutElement.tastics.map((t) => (
              <TasticWrapper
                tastics={tastics}
                key={t.tasticId}
                data={t}
                categories={categoriesData}
                dataSources={data.data.dataSources}
                pageFolder={data.pageFolder}
                highlight={currentHighlight === t.tasticId}
                serverUrl={data.serverUrl}
                serverState={data.serverState}
              />
            ))}
          </LayoutElement>
        ))}
      </Grid>
      <div id="main-content" />
      <Grid
        gridClassName={gridClassName}
        wrapperClassName={`main-content-wrapper w-full grow ${highlightClassNames(currentHighlight === 'main')}`}
      >
        {data?.page?.sections?.main?.layoutElements.map((layoutElement: LayoutElementType) => (
          <LayoutElement
            size={layoutElement.configuration.size}
            className={cx(
              highlightClassNames(currentHighlight === layoutElement.layoutElementId),
              deviceVisibility(layoutElement.configuration),
            )}
            key={layoutElement.layoutElementId}
          >
            {layoutElement.tastics.map((t: Tastic) => (
              <TasticWrapper
                tastics={tastics}
                key={t.tasticId}
                data={t}
                categories={categoriesData}
                dataSources={data.data.dataSources}
                pageFolder={data.pageFolder}
                highlight={currentHighlight === t.tasticId}
                serverUrl={data.serverUrl}
                serverState={data.serverState}
              />
            ))}
          </LayoutElement>
        ))}
      </Grid>
      <Grid
        gridClassName={gridClassName}
        wrapperClassName={`w-full ${highlightClassNames(currentHighlight === 'footer')}`}
      >
        {data?.page?.sections?.footer?.layoutElements.map((layoutElement: LayoutElementType) => (
          <LayoutElement
            size={layoutElement.configuration.size}
            className={cx(
              highlightClassNames(currentHighlight === layoutElement.layoutElementId),
              deviceVisibility(layoutElement.configuration),
            )}
            key={layoutElement.layoutElementId}
          >
            {layoutElement.tastics.map((t: Tastic) => (
              <TasticWrapper
                tastics={tastics}
                key={t.tasticId}
                data={t}
                categories={categoriesData}
                dataSources={data.data.dataSources}
                pageFolder={data.pageFolder}
                highlight={currentHighlight === t.tasticId}
                serverUrl={data.serverUrl}
                serverState={data.serverState}
              />
            ))}
          </LayoutElement>
        ))}
      </Grid>
      <DeviceNotCompatibleModal />
    </div>
  );
}
